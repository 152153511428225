import { FC, ReactNode, useMemo } from 'react'
import { AlbumPage, AlbumPageType } from '@api/gql/graphql'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { AlbumHeadBottom } from '@share/album/components/album-head-bottom'

import { getPageTag } from '../constants'
import { AlbumPagePagination } from '../types'
import { getSpreadLabel } from '../utils'

import { PageLabel } from './page-label'
import { SpreadControl } from './spread-control'

type Props = {
  children: ReactNode;
  albumId: string
  index?: number
  isWide?: boolean
  id?: string
  spreadPages?: (AlbumPage & AlbumPagePagination)[]
  onEdit: () => void
}

export const SpreadLayout: FC<Props> = (props) => {
  const isMobile = useIsMobile()

  const isLabeled = useMemo(() => {
    if (!props.spreadPages?.length) {
      return false
    }

    const firstType = props.spreadPages[0].type
    return [
      AlbumPageType.Picture,
      AlbumPageType.Text,
      AlbumPageType.Additional,
      AlbumPageType.BackCover,
    ].includes(firstType as AlbumPageType)
  }, [props.spreadPages])

  const isFrontCover = useMemo(
    () => !!props.spreadPages?.find((page) => page.type === AlbumPageType.FrontCover),
    [props.spreadPages],
  )

  const onEdit = () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumSpreadClickEdit,
      album_id: props.albumId,
      spread_index: props.index,
    })

    props.onEdit()
  }

  const spreadPages = useMemo(
    () => props.spreadPages?.map((spreadPage) => (
      <div
        key={spreadPage.id}
        id={getPageTag(spreadPage.id)}
      />
    )),
    [props.spreadPages],
  )

  return (
    <div>
      {spreadPages}

      {isLabeled && (
        <PageLabel>
          {getSpreadLabel(props.spreadPages)}
        </PageLabel>
      )}

      {isMobile && isLabeled && (
        <SpreadControl
          spreadPages={props.spreadPages}
          onEdit={onEdit}
          className="mb-2"
        />
      )}

      <div
        id={props.id}
        className={props.isWide
          ? 'md:flex md:justify-center'
          : 'grid grid-cols-2 md:grid-cols-2 md:gap-4'}
      >
        {props.children}
      </div>

      {isFrontCover && <AlbumHeadBottom album_id={props.albumId} />}
    </div>
  )
}
