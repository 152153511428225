import { TaskStatus, TaskType } from '@api/gql/graphql'
import { dropTask, subscriptionOnTask } from '@api/schemas'
import { useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { ModalType } from '@modals/_types'
import { AlbumPhotosTab } from '@modals/album-photos-modal/constants'
import { useModal } from '@modals/core/use-modal'

type Props = {
  albumId?: string | null;
  onCompleted: () => void;
};

export const useDuplicateFinderStatus = (props: Props) => {
  const { openModal } = useModal()

  const [dropTaskMutation] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useSubscription(subscriptionOnTask, {
    variables: { taskType: TaskType.DuplicateFinder, input: { album_id: props.albumId } },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      if (task.status === TaskStatus.Completed) {
        await dropTaskMutation({
          variables: { taskType: TaskType.CreateAlbums, input: {} },
        })

        toast.infoButton(
          task.steps[task.steps.length - 1],
          {
            buttonTitle: 'Review',
            onClick: async () => {
              amplitude.event({
                type: AmplitudeEvent.AlbumClickDeletedReview,
                album_id: props.albumId as string,
              })

              openModal({
                type: ModalType.ALBUM_PHOTOS_MODAL,
                album_id: props.albumId as string,
                tab: AlbumPhotosTab.DELETED,
              })
            },
            onDismiss: () => {
              dropTaskMutation({
                variables: {
                  taskType: TaskType.DuplicateFinder,
                  input: { album_id: props.albumId },
                },
              })
            },
            duration: 10 * 1000,
          },
        )

        props.onCompleted()
      }
    },
  })
}
