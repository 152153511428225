import { FC } from 'react'
import { Album } from '@api/gql/graphql'
import { clsx } from 'clsx'

import { AlbumItem } from './album-item'

type Props = {
  albums: Album[]
  currentAlbumId: string
  onChangeCurrentAlbumId: (albumId: string) => void
}

export const AlbumsNavigation: FC<Props> = (props) => {
  const isLess = props.albums.length <= 3

  return (
    <div className="relative">
      <div className={clsx(
        'flex flex-row gap-x-2 overflow-x-scroll scrollbar-hidden pb-2',
        isLess ? 'justify-center' : 'pl-10 pr-28',
      )}
      >
        {props.albums.map((album, index) => (
          <AlbumItem
            key={album.id}
            part={index + 1}
            isActive={props.currentAlbumId === album.id}
            onClick={() => props.onChangeCurrentAlbumId(album.id)}
          />
        ))}
      </div>

      {!isLess && (
        <div
          className="bg-gradient-to-l from-white to-white/10 w-28 h-full absolute right-0 bottom-0 pointer-events-none"
        />
      )}
    </div>
  )
}
