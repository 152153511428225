import { FC, useContext, useEffect } from 'react'
import { Album } from '@api/gql/graphql'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { WindowSizesContext } from '../context'

type Props = {
  album?: Album | null
}

export const AlbumHead: FC<Props> = (props) => {
  const { openModal } = useModal()
  const windowSizes = useContext(WindowSizesContext)
  const orderEvents = useContext(OrderEventsContext)
  const isMobile = useIsMobile()
  const headStyle = { width: `${isMobile ? windowSizes.outer.width : windowSizes.outerHalf.width}px` }

  const openAllPhotosBook = () => {
    if (!props.album?.id || !props.album.order_id) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.AlbumClickAllBookPhotos,
      album_id: props.album.id,
    })

    orderEvents.registerEvent(
      props.album.order_id,
      OrderEvent.ALL_BOOK_PHOTOS_VIEWED,
    )

    openModal({
      type: ModalType.ALBUM_PHOTOS_MODAL,
      album_id: props.album.id,
    })
  }

  useEffect(() => {
    const commonDelaySeconds = 90
    const commonTimer = setTimeout(() => {
      orderEvents.registerEvent(props.album?.order_id ?? '', OrderEvent.STAYED)
      clearTimeout(commonTimer)
    }, commonDelaySeconds * 1000)

    return () => {
      clearTimeout(commonTimer)
    }
  }, [])

  return (
    <div className="flex flex-col justify-center w-full md:mb-10" style={headStyle}>
      <div className="hidden md:block mb-1">
        <div className="text-3xl font-medium text-center mb-6">
          {props.album?.title}
          {' '}
          {props.album?.subtitle}
        </div>
      </div>

      <Button
        variant="flat"
        className="text-black bg-gray-very-light w-full"
        size={isMobile ? 'sm' : 'lg'}
        onClick={openAllPhotosBook}
      >
        All book photos
      </Button>
    </div>
  )
}
