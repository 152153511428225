import { FC } from 'react'
import { AlbumPage } from '@api/gql/graphql'
import { getProfile } from '@api/schemas'
import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import { getLiveImages, getLivePages } from '@share/album'
import { clsx } from 'clsx'

import { deleteAlbumImages } from '../api'
import { AlbumPagePagination } from '../types'

type Props = {
  className?: string
  spreadPages?: (AlbumPage & AlbumPagePagination)[]
  onEdit: () => void
}

export const SpreadControl: FC<Props> = (props) => {
  const [deleteAlbumImagesMutation, setDeleteAlbumImagesState] = useMutation(deleteAlbumImages)
  const apolloClient = useApolloClient()

  const onDelete = () => {
    const profileCache = apolloClient.readQuery({
      query: getProfile,
    })

    const spreadImageIds = getLiveImages(getLivePages(props.spreadPages ?? []))
      .map((spreadImage) => spreadImage.id)

    amplitude.event({
      type: AmplitudeEvent.AlbumDeleteImage,
      image_ids: spreadImageIds,
      image_count: spreadImageIds.length,
      user_role: profileCache?.profile.role ?? undefined,
    })

    if (!spreadImageIds.length) {
      return
    }

    deleteAlbumImagesMutation({
      variables: { albumImageIds: spreadImageIds },
      onCompleted: () => {
        toast.success(`${spreadImageIds.length} photo(s) have been deleted`)
      },
    })
  }

  return (
    <div className={clsx(
      'flex flex-row gap-2',
      props.className,
    )}
    >
      <Button
        variant="flat"
        className="text-black bg-gray-very-light w-full"
        startContent={<FontAwesomeIcon icon={faPencil} className="text-gray-400" />}
        size="sm"
        onClick={props.onEdit}
      >
        Edit
      </Button>

      <Button
        variant="solid"
        color="danger"
        className=""
        isIconOnly
        isLoading={setDeleteAlbumImagesState.loading}
        startContent={<FontAwesomeIcon icon={faTrashCan} className="text-white" />}
        size="sm"
        onClick={onDelete}
      />
    </div>
  )
}
