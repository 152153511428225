import { FC } from 'react'
import { clsx } from 'clsx'

type Props = {
  part: number
  isActive: boolean
  onClick: () => void
}

export const AlbumItem: FC<Props> = (props) => (
  <div
    className={clsx(
      'text-base text-center min-w-[70px] py-1 cursor-pointer transition-colors',
      props.isActive
        ? 'border-b-2 border-black text-black'
        : 'border-b-2 border-transparent text-gray-400 hover:text-black',
    )}
    onClick={props.onClick}
  >
    {`Part ${props.part}`}
  </div>
)
