import {
  FC,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import { AlbumPage, AlbumPageType } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { toast } from '@components/toast'
import { useApolloError } from '@helpers/apollo-error'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { getAlbum, refreshImages } from './api'
import {
  AlbumCoverHead,
  AlbumHead,
  PageItem,
  RefreshImagesStatus,
  SpreadLayout,
} from './components'
import { getPageTag } from './constants'
import { PictureControlContext, WindowSizesContext } from './context'
import { PictureControls } from './types'
import { useDuplicateFinderStatus, usePages, useWindowSizes } from './utils'

type Props = {
  albumId: string;
  editable: boolean;
  children?: (count: number) => ReactNode;
};

export const AlbumView: FC<Props> = (props) => {
  const isMobile = useIsMobile()
  const { openModal } = useModal()

  const [refreshImagesMutation] = useMutation(refreshImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: props.albumId as string },
    onCompleted: async (res) => {
      if (!res.album?.order_id) {
        return
      }

      await refreshImagesMutation()
    },
  })

  const pageError = useApolloError([
    getAlbumState.error,
  ])

  const album = getAlbumState.data?.album ?? null
  const pages = album?.pages ?? []

  const { pagesLive, spreads } = usePages(pages)
  const windowSizes = useWindowSizes()
  useDuplicateFinderStatus({ albumId: props.albumId, onCompleted: getAlbumState.refetch })

  // Скролл к выбранной странице
  const scrollToPage = (albumPageId: string) => {
    const albumPageElement = document.getElementById(getPageTag(albumPageId))
    if (!albumPageElement) {
      return
    }

    albumPageElement.scrollIntoView({ behavior: 'smooth' })
  }

  // Переход к развороту
  const onNavigatePage = useCallback((albumPage: AlbumPage) => {
    if (!isMobile) {
      return
    }

    if ([AlbumPageType.Picture, AlbumPageType.Text].includes(albumPage.type as AlbumPageType)) {
      openModal({
        type: ModalType.ALBUM_PAGE_MODAL,
        album_id: props.albumId,
        album_page_id: albumPage.id,
        onAlbumPage: scrollToPage,
      })
    }
  }, [spreads, props.albumId, isMobile])

  const pictureControl = useMemo((): PictureControls => ({
    delete: !isMobile,
    move: !isMobile,
  }), [isMobile])

  const onNavigatePageHandlers = useMemo(() => {
    const handlers: Record<string, () => void> = {}
    spreads.forEach((spreadPages) => {
      spreadPages.forEach((page) => {
        handlers[page.id] = () => onNavigatePage(page)
      })
    })
    return handlers
  }, [spreads, onNavigatePage])

  const onNavigateEditHandlers = useMemo(() => {
    const handlers: Record<number, () => void> = {}
    spreads.forEach((spreadPages, index) => {
      if (spreadPages.length) {
        handlers[index] = () => onNavigatePage(spreadPages[0])
      } else {
        handlers[index] = () => {}
      }
    })
    return handlers
  }, [spreads, onNavigatePage])

  return (
    <WindowSizesContext.Provider value={windowSizes}>
      <div className="flex flex-col gap-y-2 md:gap-y-5 relative" ref={windowSizes.ref}>
        {windowSizes.isMounted && !!album && (
          <>
            <Alert type={AlertType.error} message={pageError} />

            <div className="flex flex-col items-center w-full">
              <AlbumHead album={getAlbumState.data?.album} />

              <AlbumCoverHead
                albumId={album.id}
                editable={props.editable}
              />
            </div>

            <PictureControlContext.Provider value={pictureControl}>
              {album && spreads.map((spreadPages, spreadIndex) => (
                <SpreadLayout
                  key={spreadIndex}
                  index={spreadIndex}
                  albumId={album.id}
                  isWide={spreadIndex === 0}
                  spreadPages={spreadPages}
                  onEdit={onNavigateEditHandlers[spreadIndex]}
                >
                  {spreadPages.map((page, index) => (
                    <PageItem
                      key={page.id}
                      album={album}
                      page={page}
                      spreadIndex={spreadIndex}
                      isLeft={index === 0}
                      isRight={index === 1}
                      isControl
                      isSortable
                      isWide={spreadIndex === 0 && isMobile} // В mobile страница на всю ширину
                      isDivider={spreadPages.length > 1}
                      onClick={onNavigatePageHandlers[page.id]}
                      spreadPages={spreadPages}
                      upSpreadPages={spreads[spreadIndex - 1] ?? []}
                      downSpreadPages={spreads[spreadIndex + 1] ?? []}
                    />
                  ))}
                </SpreadLayout>
              ))}
            </PictureControlContext.Provider>

            {props.children ? props.children(pagesLive.length) : <></>}

            {album?.order_id && (
              <RefreshImagesStatus
                onCompleted={getAlbumState.refetch}
              />
            )}
          </>
        )}
      </div>
    </WindowSizesContext.Provider>
  )
}
